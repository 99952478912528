
<template>
  <empty-layout>
    <main class="section is-medium">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6">
            <div class="box has-padding-4 has-text-centered content">
              <p>
                <img v-lazy="`$/images/voucher.png`" alt="Voucher" width="120px">
              </p>
              <h1 class="title is-5">
                <span v-t="{ path: 'out.title', args: { merchant } }"></span>
              </h1>
              <p class="is-flex" style="flex-direction: column; align-items: center" @click="openDeal()">
                <span class="deal-button-code" v-show="!!code">{{ code }}</span>
                <button :class="['button', 'deal-button', 'is-tertiary', !!code ? 'has-code' : '']">
                  <span v-t="`buttons.copy-shop`"></span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </empty-layout>
</template>

<script>
export default {
  computed: {
    merchant () {
      return this.$route.query.merchant || 'the retailer website'
    },

    code () {
      return this.$route.query.code || ''
    }
  },

  methods: {
    openDeal () {
      copyToClipboard(this.code)

      // Redirect to the deal tracking link.
      location.href = this.$route.query.url
    }
  }
}
</script>
